@import "./locomotive-scroll.scss";

.cursor {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  contain: layout style size;
  pointer-events: none;
  top: -6px;
  left: -6px;
  width: 12px;
  height: 12px;
  transform: scale(0.2);
  background: #ffe0dc;
  border-radius: 50%;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 1px;
    display: block;
    width: 10px;
    height: 2px;
    transform: scale(0.3);
    background: rgb(77, 70, 70);
  }

  &:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 5px;
    display: block;
    width: 2px;
    height: 10px;
    transform: scale(0.3);
    background: rgb(77, 70, 70);
  }
}

.cursor-expand {
  position: fixed;
  transform-origin: 0, 0;
  z-index: 100;
  contain: layout style size;
  pointer-events: none;
  top: -18px; //ou -18px si bug ou 2px//
  left: -18px; //ou -18px si bug ou 2px//
  width: 48px;
  height: 48px;
  transform: scale(0);
  background: #ffe0dc;
  border-radius: 50%;

  &:before {
    content: "";
    position: absolute;
    top: 23.5px;
    left: 14px;
    display: block;
    width: 20px;
    height: 1px;
    transform: scale(0.8);
    background: rgb(77, 70, 70);
  }

  &:after {
    content: "";
    position: absolute;
    top: 14px;
    left: 23.5px;
    display: block;
    width: 1px;
    height: 20px;
    transform: scale(0.8);
    background: rgb(77, 70, 70);
  }
}
.hero-inner {
  text-decoration: none;
}
